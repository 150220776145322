<template>
  <div class="notify">
    <b-card>
      <!-- Other stuff -->
      <b-overlay :show="show">
        <b-form @submit="onSubmit">
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group
                label="Bật/Tắt"
                label-cols-md="2"
              >
                <b-form-checkbox
                  name="check-button"
                  v-model="donate_setting.status"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Url donate"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  ref="mylink"
                  :value="url+donate_setting.token"
                  type="text"
                  rel="noopener noreferrer"
                  placeholder="Token donate alert address"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                variant="primary"
                @click="copyURL"
              ><feather-icon icon="CopyIcon" size="12" />
              Copy</b-button>
              <b-button
                variant="danger"
                @click="generationURL"
              ><b-spinner
                v-if="loading_generate"
                small
              /> <feather-icon icon="RefreshCwIcon" size="12" />
              đổi url mới</b-button>
              <b-button
                variant="success"
                @click="testDonate"
              ><b-spinner
                v-if="loading_test"
                small
              /> <feather-icon icon="SendIcon" size="12" />
              Test</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Nội dung hiển thị"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  :disabled="donate_setting.message_template === null"
                  v-model="donate_setting.message_template"
                  type="text"
                  rel="noopener noreferrer"
                /> {name}: tên người gửi, {amount} số tiền, {currency} đơn vị tiền (vnd hoặc usd)
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Lọc từ"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-tags
                  input-id="tags-basic"
                  v-model="donate_setting.str_filter"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Ảnh hiển thị"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-img
                  height="50px"
                  :src="donate_setting.icon_url"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                class="mb-75 mr-75"
                v-b-modal.modal-1
                @click="changeTypeGallery(1)"
                accept=".jpg, .png, .gif"
              >
                <b-spinner small v-if="loading_upload_img"/>
                <feather-icon v-if="!loading_upload_img" icon="UploadIcon" size="12" />
                Tải lên</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Chuông"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="donate_setting.sound_url"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                class="mb-75 mr-75"
                v-b-modal.modal-1
                @click="changeTypeGallery(2)"
                accept=".mp3 .ogg"
              >
                <b-spinner small v-if="loading_upload_sound" />
                <feather-icon v-if="!loading_upload_sound" icon="UploadIcon" size="12" />
                Tải lên</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Chuông Volume"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donate_setting.sound_volume"
                  :max="10"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Độ trễ thông báo"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donate_setting.alert_delay"
                  :max="100"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group
                label="Bật GWU đọc"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <b-form-checkbox
                  name="check-button"
                  v-model="donate_setting.google_speech"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="GWU đọc Volume"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donate_setting.text_to_speech_volume"
                  :max="10"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                type="submit"
                variant="success"
              >
                <b-spinner
                  small
                  v-if="loading_submit"
                ></b-spinner> Cập nhật</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
    <ModalGalleries
      :galleries="galleries"
      :type_gallery="type_gallery"
      :loading_gallery="loading_gallery"
      @uploadfile="uploadFile"
    />
  </div>
</template>
<script>
import {
  BOverlay, BFormTags, BSpinner, BForm, BImg, BFormGroup, BFormInput, BCard, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'

import useJwt from '@/auth/jwt/useJwt'

import ModalGalleries from './modals/Gallery.vue'

import 'vue-slider-component/theme/antd.css'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BFormTags,
    BOverlay,
    ModalGalleries,
    VueSlider,
    BImg,
    BSpinner,
    BFormCheckbox,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BForm,
    FeatherIcon,
  },
  data() {
    return {
      show: false,
      url: `${process.env.VUE_APP_WIDGET_URL}/alert/donate/`,
      loading_test: false,
      file: null,
      optionsLocal: {
        avatar: '',
      },
      type_gallery: 1,
      galleries: [],
      loading_gallery: false,

      loading_upload_img: false,
      loading_upload_sound: false,

      donate_setting: {
        id: null,
        status: false,
        message_template: null,
        sound_volume: 0,
        alert_delay: 0,
        sound_url: null,
        icon_url: null,
        google_speech: false,
        google_speech_volume: 0,
        token: null,
        str_filter: [
          'ngu quá', 'gà quá',
        ],
      },
      loading_submit: false,
      loading_generate: false,
    }
  },
  directives: {
    Ripple,
  },
  created() {
    if (this.donate_setting.id === null) {
      this.loading_submit = true
      this.loading_test = true
      this.loading_generate = true
      this.show = true
      useJwt.getDonateSetting()
        .then(({ data }) => {
          this.loading_submit = false
          this.loading_test = false
          this.loading_generate = false
          this.show = false
          const setting = data.data
          setting.google_speech = data.data.google_speech === 1
          setting.status = data.data.status === 1
          this.donate_setting = setting
        })
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      this.loading_submit = true
      useJwt.updateDonateSetting(this.donate_setting).then(({ data }) => {
        this.loading_submit = false
        if (data.result === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    uploadFile(files) {
      const formData = new FormData()
      formData.append('file', files)
      formData.append('id', this.donate_setting.id)
      if (this.type_gallery === 1) {
        this.loading_upload_img = true
      } else {
        this.loading_upload_sound = true
      }
      useJwt.uploadDonateSetting(this.type_gallery,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          if (data.result === true) {
            if (this.type_gallery === 1) {
              this.loading_upload_img = false
              this.donate_setting.icon_url = data.data
            } else {
              this.donate_setting.sound_url = data.data
              this.loading_upload_sound = false
            }
            // thay đổi url
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload success!',
                icon: 'BellIcon',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload failed!',
                icon: 'BellIcon',
              },
            })
          }
        })
    },
    changeTypeGallery(type) {
      this.type_gallery = type
      this.loading_gallery = true
      // useJwt.getGalleries(type).then(({ data }) => {
      // this.galleries = data
      // this.loading_gallery = false
      // })
    },
    testDonate() {
      this.loading_test = true
      useJwt.testDonate(this.donate_setting.id, {
        ...this.donate_setting,
      })
        .then(() => {
          this.loading_test = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Test sended!',
              icon: 'BellIcon',
            },
          })
        })
    },
    copyURL() {
      this.$refs.mylink.select()
      document.execCommand('copy')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    generationURL(e) {
      e.preventDefault()
      if (this.donate_setting.id === null) {
        return
      }
      this.loading_generate = true
      useJwt.generateToken(this.donate_setting.id).then(({ data }) => {
        this.loading_generate = false
        if (data.result === true) {
          this.donate_setting.token = data.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generated token success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
